// For complete list of CSS variables (bootstrap 5.3)
// https://getbootstrap.com/docs/5.3/customize/css-variables/

// For complete list of SCSS variables 
// @import "../../node_modules/bootstrap/scss/_variables.scss";

$enable-negative-margins: true; 

// Theme colors
$dark-purple:#2E1F44;
$violet: #552684;
$lavender:#9D86B4;
$purple: #A45EB1;

$white: #ffffff;
$dirty-white: #F6F5F6;
$dark: #532A5B;
$grey: #585858;
$primary: $violet;
$secondary: $purple;

$container-max-widths: (
  sm: 540px,
  md: 720px,
  lg: 960px,
  xl: 1200px,
  xxl: 1600px
);

$container-padding-x:3rem;

$theme-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "violet": $violet,
  "dark-purple": $dark-purple,
  "purple": $purple,
  "lavender": $lavender,
  "white": $white,
  "dirty-white":$dirty-white,
  "dark": $dark,
  "grey": $grey
);

// For other theme color
// refer to https://getbootstrap.com/docs/5.3/customize/sass/ for more details
// $success:       $green ;
// $info:          $cyan ;
// $warning:       $yellow ;
// $danger:        $red lt;
// $light:         $gray-100 !default;
// $dark:          $gray-900 !default;

// Font Color
// $primary-text-emphasis: $primary;
// $body-emphasis-color: $primary;

$body-color: $grey;
$headings-color: $purple;
// Font Family

$font-primary: "proxima-nova", sans-serif;
$font-cursive: 'Alice', serif;
$font-family-base: "proxima-nova", sans-serif;
$headings-font-family: 'Alice', serif;


// Font Sizes and Weight
$font-size-root: 18px;
$font-size-base: 1rem;
$headings-font-weight: 400;
// $body-font-weight: 700;
// $font-weight-base: 700;
// For converting px to rem
// https://nekocalc.com/px-to-rem-converter

$h1-font-size: 3.333rem; //60px
$h2-font-size: 2.667rem;  //48px
$h3-font-size: 2rem;  //36px
$h4-font-size: 1.556rem;  //28px
$h5-font-size: 1.333rem;  //24px
$h6-font-size: 1.222rem; //22px

// Lead and Small
$lead-font-size: 1.1;   //18px
$lead-font-weight: 700; //medium
$small-font-size: 16px;

// Links

$link-decoration: none;


// Navbar
$navbar-padding-y:0;
$nav-link-padding-x:1.25rem;
$nav-link-padding-y:.75rem;


$nav-link-font-weight:100;
$nav-link-font-size:16px;
$nav-link-hover-color: $white;

$navbar-light-hover-color: $white;
$navbar-light-icon-color: $white;
$navbar-light-color:$white;
$navbar-light-active-color:$white;
$navbar-nav-link-padding-x:.75rem;
$navbar-toggler-border-radius:5px;

// Dropdown
$dropdown-color: #fff;
$dropdown-bg: transparent;
$dropdown-border-radius: 0;
$dropdown-spacer: -1px;
$dropdown-border-width:0;
$dropdown-link-active-bg: $purple;
$dropdown-min-width: 100%;

// Button
$btn-border-radius: 33px;
$btn-padding-x:1.5rem;
$btn-padding-y:.5rem;
$btn-font-weight: 100;
$btn-font-size: 1rem;
$btn-color: #fff;
$input-btn-font-family: $font-cursive;
$input-btn-font-size: 18px;
// Form
$focus-ring-width: 1px;
$input-border-radius:  0px;
$input-bg: transparent;
$input-focus-border-color: $purple;
$input-focus-color:  $dark;
$input-color: #585858;
$input-font-family: $font-primary;
$input-padding-y: 1rem; 
//Pagination
$pagination-color: $purple;
$pagination-active-color: $purple;
$pagination-active-bg: transparent;  
$pagination-active-border-color: transparent;
$pagination-bg: transparent;
$pagination-border-width: 0;
$pagination-hover-color: $purple;
$pagination-hover-bg: transparent;
$pagination-disabled-bg: transparent;
$pagination-padding-y: .175rem;
$pagination-padding-x: .05rem;

$accordion-button-active-bg: white;
$accordion-button-active-color: $purple;
$accordion-button-color: $purple;
$accordion-icon-color: $violet;
$accordion-icon-active-color: $violet;
$focus-ring-width: 0;
$focus-ring-color: transparent;

$line-height-base: 1.2;

// spacer for padding and margin
$spacer: 1rem;
$spacers: (
  0: 0,
  1: $spacer * .25,
  2: $spacer * .5,
  3: $spacer,
  4: $spacer * 1.5,
  5: $spacer * 3,
  6: $spacer * 6,
  7: $spacer * 9,
);