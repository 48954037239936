@import "variables";

.cta-footer{
    a h3{
        transition: color 1s ease;
    }
    a:hover h3{
        color:$violet!important;
    }
}
footer{
    background-color: $dark-purple;
    .logo{
        max-width: none;
        width: clamp(200px, 25vw, 400px);
        height: auto;
        @include media-breakpoint-down(xl) {
           margin-left: auto;
           margin-right: auto;
        }
    }
    .footer-contact{
        @include media-breakpoint-down(xl) {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
        } 
    }
    .footer-top{
        border-bottom: 20px solid #552684;
    }
    .footer-menu{
        .row-list{
            width: auto;
            align-items: center;
        }
        .nav-item{
            display: flex;
            align-items: center;
        }
    }
    .site-map{
        ul.nav{
            gap:1rem;
            .nav-link{
                border-bottom: 1px solid transparent;
                padding-left:5px;
                padding-right:5px;
                &:hover{
                    color:$purple!important;
                    border-bottom: 1px solid $purple;
                }
            }
        }

      
    }
 
}