
.carousel-caption{
    left:0;
    right:0;
    hyphens: none;
    .h1{
        font-family: $font-cursive;
    }
}

#splash-wrap{
    min-height: 300px;
    height: 33vw;
    max-height: 750px;
    position: relative;
    overflow: hidden;
    background-color: rgba(126, 126, 126, .7);
    &::after{
        position: absolute;
        bottom: 0;
        left:50%;
        background-color: #fff;
        content:"";
        z-index: 1;
        border-radius: 50%;
        height: 10vw;
        width: 135vw;
        transform: translateX(-50%)translateY(50%);
    }
    .splash-graphic{
        mix-blend-mode: multiply;
    }
}

.carousel-item{
    background-color: rgba(126, 126, 126, .7);
    pointer-events: none;
    &> picture > img{
        mix-blend-mode: multiply;
        pointer-events: none;
    }
    
}   
.carousel-indicators{
    bottom: calc(1vh + 5vw);
    justify-content: center;
    pointer-events: none;
    gap: .5rem;
    margin-left:auto;
    margin-right: auto;
    width: 100%;
    max-width: 1800px;
    padding:0 15px;
    align-items: flex-end;

    [data-bs-target] {
        
        border-radius: 50%;
        width: 12px;
        height: 12px;
        pointer-events:all;
        transition: transform .15s ease, opacity .15s ease, height .15s ease;
        &:hover{
            transform: scale(1.1);
            opacity: 1;
        }
      
    }
    @include media-breakpoint-down(md) {
        justify-content: center;
    }
    @include media-breakpoint-down(sm) {
        display: none;
    }
}