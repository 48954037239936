@import "variables";
.t-home{
    #splash-wrap{
        min-height: 600px;
        height: 100vh;
        max-height: 100vh;
    }
}

.t-staff, .t-notice{
    .default-image{
        background-color: #F6F5F6;
        img{
            transform: scale(.8);
            filter: sepia(0%) saturate(1704%) hue-rotate(119deg) brightness(85%) contrast(92%);
        }
    }
    .item-card{
        border-bottom: 1px solid #D2D2D2;
        margin-bottom: -1px;
    }

    a.item-card{    
        img{
            transition: transform 1s ease;
        }
        &:hover{
            img{
                transform: scale(1.05);
            }
        }
        .default-image{
            img{
                &:hover{
                    transform: scale(.85);
                }
            }
        }
    }
    .item-card-list{
        border-bottom: 1px solid #D2D2D2;
    }
}

.t-staff-item, .t-notice-item{
    #splash-wrap{
        height: 25vw;
        max-height: 350px;
        
    }
}