@import "variables";

.block-wrapper{
    & > div:nth-child(odd){
        background-color: #F6F5F6;
    }
}

.block-image-content{
    @include media-breakpoint-down(md) {
        .row-list{
            justify-content: center;
        }
    }
}
.block-testimonials{
    background-color: #F6F5F6;
    .splide__slide{
        text-align: center;
        h1, h2, h3, h4, p, strong{
            font-size: 1.5rem!important;
            font-family: "Alice", serif!important;
            color: $purple!important;
            font-weight: 400!important;
        }
   
    }
    .splide__pagination__page.is-active{
        background: $purple;
        transform:none;
    }
}
