label.float{
    right:0;
    text-transform: none;

    @include media-breakpoint-down(sm) {
        padding-left:0;
    }
}
label.float, input {
    height: 50px;
    line-height: 50px;
}
.recaptcha-notice{
    text-align: right;
}
.t-contact{
    @include media-breakpoint-down(sm) {
        form button[type=submit]{
            width: 100%!important;
        }
    }
}
