
header{
    border-top:10px solid #552684;
    font-family: $font-cursive;
    .dropdown-toggle::after {
        display: none;
       
    }
    .navbar-brand{
        position: relative;
        display: block;
        width: clamp(200px, 20vw, 405px);
        max-width: none;
        height: auto;
        padding: 0;
        align-self: stretch;
        text-align: right;
        z-index:1001;
        margin:0;
        img{
            width: 100%;
            height: 100%!important;
        }
    }
    @include media-breakpoint-down(lg) {
        padding-top: 2rem;
    }
    .nav-link{
        display: flex;
        align-items: center;
        transition: text-shadow .2s ease-in-out, border-bottom .15s ease-in-out ;
       
        font-size: 18px;
        border-bottom: 1px solid transparent;
   
        padding-top: 15px;
        padding-bottom: 15px;
        &:hover{
            border-bottom: 1px solid #fff;
        }
    }
    .depth-0{
        & > .nav-item{
            height: 100%;
            display: flex;
            align-items: center;
        }
    }
    .nav-item.dropdown{
        height: 100%;
        display: flex;
        align-items: center;
     
        .btn-group .nav-link{
            margin: auto;
            display: flex;
            // border-bottom: 1px solid transparent!important;

        }
    }
    .dropdown-menu .dropdown-menu {
        display: none;
        position: absolute;
        left: 100%;
        top: 0;
       
    }
    .depth-0 > .nav-item > .btn-group > .dropdown-toggle-split{
        padding-left: .25rem;
        padding-right: .25rem;
    }
    .depth-1 .dropdown-toggle::after{
        margin-left: 1rem;
    }
    .dropdown-menu> li:hover > .btn-group > .dropdown-menu {
        display: block;
        
    }

    .dropdown-toggle-split, .dropdown-toggle{
        display: flex;
        align-items: center;
    }

    .btn-group{
        height: 100%;
        position: relative;
        width: 100%;
        & > .nav-link{
            height: auto;
            align-items: flex-start;
            align-self: flex-start;
            // border-bottom: 1px solid transparent!important;
            transition: none;
        }
        .dropdown-menu{
            margin-top: 0;
            margin-bottom:0;
            padding-top: 0;
            padding-bottom:0;
           
            .nav-item{
                padding: 0!important;
                width: 100%;

                .depth-2 .nav-link{
                    background-color: transparent;
                    color: #4d4d4d;
                    &:hover{
                        color: #fff;
                    }
                }
                .nav-link{
                    padding: .5rem .75rem;
                    margin: 0;
                    white-space: nowrap;
                    color:#fff;
                    font-size: 16px;
                    width: 100%;
                    display: block;
                
                    &:hover{
                        background-color: transparent;
                    }
                }
            }
            &.depth-1{
                padding: 1rem 0;
                top:100%;
                margin-top: 0;
                text-align: center;
                background: rgba(164,94,177,.5);
                padding: 0.75rem;
            }
        }
        .dropdown-toggle{
            display: none;
            
        }
    }
    .header-menu{
        list-style: none;
        display: flex;
        align-items: center;
        gap:1.5rem;
        padding:0;
        margin:0;
        a{
            text-decoration: none;
            color:$green;
            font-size: 14px;
            &:hover{
                color:$white;
             
            }
        }
    }
    .header-menu-top{
        a {
            transition: color .3s ease;
            svg{
                color: $purple;
                transition: color .3s ease, fill .3s ease;
                height: 25px;
                width: 25px;
                object-fit: contain;
            }
            &:hover svg, svg:hover{
                color: $white!important;
                fill: $white!important;
            }
        }
    }
}
.offcanvas-header{
    background-color: var(--bs-primary);
    color: var(--bs-white);
}
.collapse-menu{
    .has-collapse-button{
        align-items: center;
        justify-content: space-between;
    }
}
.offcanvas-title {
   color: #fff;
}