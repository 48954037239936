
// Author's custom styles
// we need to import this first so that it would override bootstrap scss variables later
@import url('https://fonts.googleapis.com/css2?family=Alice&family=Poppins:wght@300;400;600;700&display=swap');
@import '@splidejs/splide/css';
@import "variables";
@import "../../node_modules/bootstrap/scss/bootstrap.scss";
@import "../../node_modules/glightbox/dist/css/glightbox.css";

// Our own scss below
@import "header";
@import "splash";
@import "page";
@import "block";
@import "footer";
@import "contact";

.proxima-nova{
    font-family: $font-primary;
}



.alice{
    font-family: $font-cursive;
} 

.img-tint{
    background-color: rgba(83, 42 ,91, .5);
    transition: background-color 1s ease;
    img{
        mix-blend-mode: multiply;
    }
}

.img-tint-hover:hover .img-tint{
    background-color:transparent;
}

.row-list{
    padding:0;
    margin:0;
    display: flex;
    justify-content: flex-start;
    list-style: none;
    gap:1rem;
    width: 100%;

}

.two-col{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1rem;
    padding: 0;
    margin: 0;
    list-style: none;
}

.page-item.active{
    font-weight: bold;
    border-bottom: 2px solid $purple;
} 

.pager-arrows.disabled{
    display: none;
}

.pager-arrow-a{
    display: flex;
    gap: 1rem;
}

.arrow-prev .pager-arrow-icon{
    display: block;
    position: relative;
    width: 10px;
    color:#fff;
    &::after{
        content:url('../assets/next.svg');
        display: block;
        position: absolute;
        top:0;
        left:0;
    }
}

.arrow-next .pager-arrow-icon{
    width: 10px;
    overflow: hidden;
    display: block;
    position: relative;
    color:#fff;
    &::after{
        content:url('../assets/next.svg');
        display: block;
        position: absolute;
        top:0;
        left:0;
        transform: rotate(180deg)translateY(4px);
    }
}

.download-icon{
    content:url('../assets/download.svg');
    display: block;
}

.search-form{
    button{
        height: 50px;
    }
}

ul.purple-bullet{
    list-style: none;
    li{
        position: relative;
        &::before{
            content:"";
            background-color: $purple;
            border-radius: 50%;
            height: 5px;
            width: 5px;
            position: absolute;
            right: 100%;
            margin-right: 15px;
            margin-top: 9px;
        }
    }
}

//bootstrap class overrider
.btn-primary{
    --bs-btn-active-bg: #9D86B4;
    --bs-btn-active-border-color: #9D86B4;
    &:hover{
        --bs-btn-active-bg: #A45EB1;
        --bs-btn-active-border-color: #A45EB1;
        --bs-btn-hover-bg: #A45EB1;
        --bs-btn-hover-border-color: #A45EB1;
    }
}

.border-grey{
    --bs-grey-rgb:210, 210, 210;
}

.accordion-button {
    font-size: inherit;
}

.btn{
    align-self: center;
    display: inline-flex;
    gap: 10px;
}

.btn-link{
    border-bottom: 1px solid;
    border-radius: 0;
    padding-left: 5px;
    padding-right: 5px;
    &:hover{
        color:#A45EB1;
        border-bottom: 1px solid #A45EB1;
    }
}
.has-collapse-button .btn-link{
    border-bottom: 1px solid transparent!important;
}